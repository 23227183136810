import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { email } from '@config';
import { navDelay, loaderDelay } from '@utils';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 10px;
    color: var(--slate);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 500px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <h1>Hey, my name is</h1>;
  const two = <h2 className="big-heading">Jeffrey Zhang</h2>;
  const three = (
    <h3 className="medium-heading">
      swe @{' '}
      <a href="https://instabase.com/" target="_blank" rel="noopener noreferrer">
        instabase
      </a>{' '}
      / waterloo cs
    </h3>
  );
  const four = (
    <>
      <p>
        I design and build frictionless products for humans. Currently building intelligent document
        processing at{' '}
        <a href="https://instabase.com/" target="_blank" rel="noopener noreferrer">
          Instabase
        </a>
        .
      </p>
    </>
  );
  const five = (
    <div>
      <a href={`mailto:${email}`} className="email-link">
        Reach Out
      </a>
      <a
        style={{ marginLeft: '20px' }}
        className="email-link"
        href="/Resume.pdf"
        target="_blank"
        rel="noopener noreferrer">
        Resume
      </a>
    </div>
  );

  const items = [one, two, three, four, five];

  return (
    <StyledHeroSection>
      <TransitionGroup component={null}>
        {isMounted &&
          items.map((item, i) => (
            <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
              <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
            </CSSTransition>
          ))}
      </TransitionGroup>
    </StyledHeroSection>
  );
};

export default Hero;
